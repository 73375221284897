.Item{
    position: relative;
    width:100%;
    margin: auto;
    height: 500px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 1px 2px rgb(157, 157, 157);
    transition: all 0.2s linear;
}
.Item:hover{
    transform: scale(1.02);
}
.Item>div{
    width: 100%;
    height: 100%;
}
.Item>div>img{
    width: 100%;
    height: 100%;
    object-fit:cover;
}
.Item footer{
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: start;
    border-radius: 0px 0px 20px 20px;
}
.Item footer .itemInfo h3, .Item footer .itemInfo span{
    width: 100%;
    display: block;
    overflow: hidden;
    cursor: pointer;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}
.Item footer h3{
    font-size: 24px;
    margin: 5px 0 0 0;
}
.itemInfo{
    background-color: white;
    padding: 20px 10px;
}
.itemInfo .itemRating{
    color: var(--lgmd-color-primary);
}
button.disabled{
    cursor: not-allowed!important;
    background-color: rgba(0, 0, 0, 0.3);
}
.quantitySpan{
    cursor: pointer;
}

.Item footer .addItem{
    display: flex;
    justify-content: end;
    padding: 5px ;
}
.Item footer .addItem>span{
    height: 50px;
    border-radius: 25px;
    background-color: white;
}
.Item footer .addItem>span>span{
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.Item footer .addItem  button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
}
.Item footer .addItem button:first-child{
    background-color: rgb(236, 167, 167);
    color: red;
}
.Item footer .addItem button:last-child{
    background-color: rgb(172, 239, 172);
    color: green;
}
.Item footer .addItem  button:active{
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width:800px) {
    .Item{
        width: 80%;
        height: 300px;
    }
    .Item footer h3{
        font-size: 16px;
    }
    .Item footer span{
        font-size: 13px;
    }
}
