.categoryContainer{
    width: 100%;
    height: auto;
    background-color: white;
    box-shadow: 0 0 4px lightgray;
    display: flex;
    justify-content: center;
    border-radius: 4px;
}
.categoryItem{
    height:auto;
    padding: 10px 6px;
    margin: 0px 10px;
    border-radius: 5px;
    transition: transform 0.2s ease-in-out;
    border: 1px solid rgb(205, 205, 205);
    /* background-color: var(--lgmd-color-primary); */
}
.categoryItem:hover{
    transform: scale(1.04);
}

.categoryContainer ul{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    padding: 0;
    margin: 0;
    justify-content: space-around;
    list-style: none;
    padding: none;
}
.categoryItem img{
    width: 200px;
    height: 200px;
    object-fit: contain;
}
.categoryContainer> ul > li{
    position: relative;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
}


@media screen and (max-width:1000px) {
    .categoryItem img{
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
    .categoryContainer{
        position: fixed;
        top: 0;
        left: -100%;
        width: 50%;
        height: 100vh;
        justify-content: start;
        flex-direction: column;
        z-index: 100;
        overflow-y:scroll;
        transition: left 0.3s ease-in-out;
    }
    .categoryContainer ul{
        grid-template-columns: repeat(1,1fr);
    }
    .categoryContainer> ul > li{
        position: relative;
        border-bottom: 1px solid rgb(218, 218, 218);
    }
    .categoryContainer.expand{
        left: 0;
    }
}