.main{
    background-color: white;
    padding: 30px 0;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 10px rgb(139, 139, 139);

}
.carouselSlider{
    position: relative;
    display: flex!important;
    width: 95%;
    height: auto;
    border: 1px solid lightgray;
}
.carouselimg {
    transition: width 0.5s ease;
    overflow: hidden; 
    cursor: pointer;
}
.carouselimg img{
    position: relative;
    width: 100%;
    height: 60vh;
    object-fit:cover;
    display: block;
}

.newArrivals,.trending{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
}
h3{
    text-align: start;
}

@media screen and (max-width:1440px) {
    .newArrivals,.trending{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 20px;
    }
}

@media screen and (max-width:992px) {
    .newArrivals,.trending{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
    }
}
@media screen and (max-width:800px) {
    .main{
        width: 95%;
        margin: auto;
    }
    .carouselimg img{
        position: relative;
        width: 100%;
        height: 30vh;
        object-fit:cover;
        display: block;
    }
    .newArrivals,.trending{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
    }
    .newArrivalItem,.trendingItem{
        width: 80%;
        min-height: 300px;
    }
}