:root{


  --lgmd-color-primary: #E6C744;
  --lgmd-color-secondory:#FFFFFF;
  --lgmd-color-primary-nml:#000000;
  --lgmd-color-primary-bld:#C2C8DA;
  --lgmd-color-primary-blder:#666666;
  --popins-black:'Popins';

  --input-gray-bg:#e4e4e4;
  

  --dkmd-color-primary: #2c2c2c;
  --dkmd-color-primar-lgt:#454545;
  --dkmd-color-primary-nml:#5b5b5b;
  --dkmd-color-primary-bld: #7e7e7e;
  --dkmd-color-primary-blder:#adadad;

}

.App {
  text-align: center;
  width: 100%;
  height: auto;
  user-select: none;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.slick-list {
  overflow: hidden;
  height: 60vh;
  width: 100%;
  border-radius: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-margin {
  margin: 0 !important;
}

@media screen and (max-width:800px) {
  .slick-list {
    height: 30vh;
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 10px;
  border: 3px solid #f1f1f1; /* Creates padding around the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
/* Additional customization to hide scroll arrows in older browsers */
.scrollbar-hidden-buttons {
  scrollbar-width: none; /* Firefox (hides scrollbar buttons and scrollbar) */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.scrollbar-hidden-buttons::-webkit-scrollbar {
  display: none; /* Hides scrollbar entirely in WebKit browsers */
}