.shopcontainer{
    width: 100%;
    height: 70vh;
}
.filtercontainer{
    width: 80%;
    min-height: auto;
}
.searchfilter {
    display: flex;
}
.searchfilter input{
    width: 300px;
    border: none;
    padding: 10px;
    box-shadow: 0px 0px 2px gray;
}
.searchfilter input:focus{
    outline: none;
    box-shadow: 0px 0px 3px gray;
}
.searchfilter input::placeholder{
    padding-left: 10px;
}
.searchfilter .searchicon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    box-shadow: 0px 0px 3px gray;
    border-radius: 0 3px 3px 0;
    background-color: var(--lgmd-color-primary);
    cursor: pointer;
}
.searchfilter>span{
    cursor: pointer;
    transition: all 0.1s linear;
}
.searchfilter>span:hover{
    transform: scale(1.1);
}

.filter{
    position:relative;
    width: 100%;
    min-height: 0vh;
    height: 0px;
    background-color: var(--lgmd-color-primary);
    box-shadow: 0px 0px 3px gray;
    border-radius:4px;
    transition: all 0.1s linear;
    z-index: 100;
    overflow: hidden;
}

.filter.show{
    height: 100px;
    min-height: 10vh;
    height: auto;
    padding-bottom: 20px;
}
.filter header{
    width: 100%;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: start;
    border-bottom: 1px solid rgba(148, 148, 148,0.4);
    border-radius: 5px 0 0 0;
}
.filter >footer> button{
    padding: 10px 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 3px;
    transition: transform 0.1s ease-in-out;
}
.filter >footer> button:hover{
   transform: scale(1.1);
}
.filteritems{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    font-size: 15px;
}
.filteritems>span{
    text-align: start;
    margin: 10px;
    padding: 5px 0;
    font-weight: bold;
}
.filteritems>span:first-child{
    border: none;
}

.filteritems>span>fieldset{
    font-weight: normal;
}
.filteritems>span>fieldset input[type='checkbox']{
    width: 15px;
    height: 15px;
}

.itemscontainer{
    width: 80%;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 20px;
    margin: 30px 0px;
}


@media screen and (max-width:1440px) {
    .itemscontainer{
        grid-template-columns: repeat(4,1fr);
    }
}

@media screen and (max-width:480px) {
    .itemscontainer{
        grid-template-columns: repeat(1,1fr);
    }
    .filter{
        width: 90%;
        height: 0px;
        overflow: hidden;
        padding: 0;
        min-height: 0vh;
    }
    .filteritems{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        font-size: 15px;
    }
}