.main{
    width: 100%;
    min-height: 10vh;
    padding: 20px 0;
    background-color: var(--lgmd-color-primary);
}
.connectwithusInput input , .connectwithusInput span{
    padding: 10px;
}

.connectwithusInput input{
    border: none;
}
.connectwithusInput input:focus{
    border: none;
    outline: none;
}


.connectwithusInput span{
    background-color: var( --dkmd-color-primary);
    color: white;
}