.contactuscontainer{
    width: 100%;
    height: 60vh;
}
.information{
    text-align:start;
    height: 100%;
}

.information h3{
    margin: 0;
}

.location{
    height: 100%;
}

.location h3{
    margin: 0;
}