.aboutuscontainer{
    height: 30vh;
    width: 100%;
    color: white;
    background-color: var(--lgmd-color-primary-nml);
}
.aboutuscontainer>.connect {
    text-align: start;
    padding: 0 40px;
}
.socialIcon{
    font-size: 26px;
}
.socialIcon a{
    appearance: none;
    color: inherit;
    cursor: pointer;
}
.quicklinks{
    text-align: start;
}
.quicklinks span{
    font-weight: bold;
    padding: 0 4px;
}
.quicklinks ul{
    font-weight: lighter;
    color: rgba(153, 153, 153, 0.784);
    appearance: none;
    list-style: none;
    padding: 0;
}
.quicklinks li{
    cursor: pointer;
    padding: 4px 0;    
}
.quicklinks li:hover{
    color: rgba(212, 212, 212, 1);
}

@media screen and (max-width:1000px) {
    .aboutuscontainer{
        font-size: 12px;
    }
    .aboutuscontainer>.connect {
        text-align: start;
        padding: 0 10px;
    }
    .socialIcon{
        font-size: 16px;
    }
    .quicklinks{
        text-align: end;
        padding: 0 20px;
    }

}