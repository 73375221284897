.sidebaroutercontainer {
    position: relative;
    height: 10vh;
    z-index: 10;
    transition: all 0.1s linear;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 3px black;
}

.logoContainer img {
    width: 150px;
    height: 70px;
    object-fit: contain;
}
.logoContainer span {
    font-size:24px;
    color: black;
    line-height: 0;
}
.loginProfile{
    cursor: pointer;
}

.loginProfile img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.searchContainer{
    height: 40px;
    display: flex;
}
.searchContainer input{
    width: 60%;
    justify-self: start;
    height: 100%;
    padding: 10px;
    border: 0.2px solid rgb(220, 220, 220);
    background: var(--input-gray-bg);
    /* box-shadow: 0 0 2px rgb(98, 98, 98); */
    border-left: none;
    transition: all 0s ease-in-out;
    
}

.searchContainer input:focus{
    border: 0.2px solid rgb(220, 220, 220);
    border-left: none;
    outline: none;
}
.searchContainer>span{
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    border: 0.2px solid rgb(220, 220, 220);
    border-right: none;
    background: var(--input-gray-bg);
    color: var(--lgmd-color-primary-nml);
}

.mobilemenu{
    font-size:18px!important;
    padding: 4px 7px;
    border: 1px solid rgb(224, 224, 224);    
}
.cart{
    cursor: pointer;
    border-radius: 4px;
    padding: 4px 7px;
    height: auto;
    /* border: 1px solid black; */
}

.cart span ,.loginProfile{
    font-size:24px;
    color: black;
    line-height: 0;
}
.cartIcon{
    position: relative;
}
.noofitemincart{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    transform: translate(50%,-50%);
    border-radius: 50%;
    font-size: 11px!important;
    background-color: var(--lgmd-color-primary);
    color: white!important;
}
.aboutus a , .contactus a{
    position: relative;
    width: auto;
    text-decoration: none;
    color: black;
    font-size: 16px;
    transition: all 0.3s linear;
}
.aboutus a::after ,.contactus a::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 2px;
    background-color: black;
    transition: all 0.3s linear;
}
.aboutus a:hover::after ,.contactus a:hover::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 0.3s linear;
}
.aboutus a:hover , .contactus a:hover{
    text-decoration: none;
    font-size: 18px;
}

@media screen and (max-width:1000px) {
    .sidebaroutercontainer {
        height: 12vh;
    }
    .logoContainer img {
        width: 40px;
        height: 20px;
    }
    .loginProfile{
        cursor: pointer;
    }
    .loginProfile img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    .searchContainer{
        height: 40px;
        background-color: white;
    }
    .searchContainer input{
        width: 80%;
    }
    .searchContainer input , .searchContainer span{
        height: 30px;
    }
    .searchContainer{
        position: absolute;
        top: 10vh;
    }
    .noofitemincart{
        width: 15px;
        font-size: 11px!important;
    }

    .aboutus a , .contactus a{
        font-size: 11px;
    }
    .aboutus a:hover , .contactus a:hover{
        font-size: 13px;
    }

}