.dashboardoutercontainer{
    position: relative;
    min-height: 100vh;
    height: auto;
    transition: all 0.1s linear;
    margin-bottom: 100px;
    width: 90%;
}
@media screen and (max-width:1000px) {
    .dashboardoutercontainer{
        width: 100%;
    }

}