.itemdetailcontainer{
    width: 90%;
    min-height: 90vh;
    height: auto;
    padding: 20px 20px;
    background-color: white;
}
.itemdescription h3 ,.itemdescription span{
    text-align: start;
    display: flex;
    width: 100%;
    height: auto;
}

.itemimages > div:first-child{
    width: 500px;
    height: 300px;
    padding: 10px 0;
    box-shadow: 0 0 4px lightgray;
}
.itemimages > div:first-child img{
    width: 500px;
    height: 300px;
    object-fit: contain;
}
.itemimages img{
    cursor: pointer;
}
.otheriamges{
    display: grid;
    grid-template-columns: repeat(4,2fr);
    margin: 20px 0;
}
.otheriamges img{
    width: 50px;
    height: 50px;
    margin: 10px 0;
    border: 1px solid black;
}
.sizes{
    display: grid;
    grid-template-columns: repeat(1fr,3);
    gap: 30px;
}

.size{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 30px;
    height: 30px;
    border: 1px solid black;
}

.size span{
    display: none;
}
.size .notavailable{
    position: absolute;
    color: red;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.size:active{
    background-color: gray;
    color: white;
}
.recommendation span{
    display: inline;
    margin: 30px 0;
    text-align: start;
}
.recommendeditems {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
}

@media screen and (max-width:480px) {
    .itemimages > div:first-child{
        width: 300px;
        height: 200px;
        padding: 10px 0;
        box-shadow: 0 0 4px lightgray;
    }
    .itemimages > div:first-child img{
        width: 300px;
        height: 200px;
        object-fit: contain;
    }
}