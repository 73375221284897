.aboutuscontainer{
    width: 100%;
    height: 60vh;
}

.ourstory {
    font-size: 26px;
    font-weight: lighter;
}

.ourstory p{
    text-align: start;
}